import React from "react"
import "../../src/components/styles/dummypage.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default () => (
  <Layout>
    <SEO
      title="Terms of Use"
      description="Kubric's terms and policies, including privacy, terms of service, API terms, security and more."
    />
    <main className={"security"}>
      <div class="body-section tnc">
        <h1 class="heading">
          <a name="introduction">Introduction</a>
        </h1>
        <p class="text">
          This is a legal agreement between you, the potential user of this
          website ("you"), and Kubric.io, both Kubric and Studio.Kubric
          (“Kubric”). Your use of Kubric’s products, services and web sites,
          this website, or any of the products and services offered on this Site
          (collectively, the "Services") is conditioned on your acceptance,
          without modification, of these Terms of Service ("Terms"). Please read
          these Terms carefully. If you do not agree to these Terms, you should
          not use the Services. You can accept the Terms simply by using the
          Kubric Services. In this case, you understand and agree that Kubric
          will consider your use of the Services as agreement to the Terms.
          Kubric may, at its sole discretion, modify these Terms at any time,
          and such modifications will be effective immediately upon being posted
          on this Site.
        </p>
        <h2 class="heading">
          <a name="provisionsofservices">Terms of Use</a>
        </h2>
        <p class="text">
          Kubric is constantly innovating in order to provide the best possible
          experience for its users. You acknowledge and agree that the form and
          nature of the Services, which Kubric provides, may change from time to
          time without prior notice to you. As part of this continuing
          innovation, you acknowledge and agree that Kubric may stop
          (permanently or temporarily) providing any of the Services to you or
          to users generally at Kubric’s sole discretion, without prior notice
          to you. You may stop using the Services at any time. You acknowledge
          and agree that if Kubric disables access to your account, you may be
          prevented from accessing the Services, your account details, or any
          files or other content that is contained in your account.
        </p>
        <h2 class="heading">
          <a name="useoftheservicesbyyou">Use of the services by you</a>
        </h2>
        <p class="text">
          In order to access certain Services, you may be required to provide
          information about yourself (such as identification or contact details)
          as part of the registration process for the Service, or as part of
          your continued use of the Services. You agree that any registration
          information you give to Kubric will always be accurate, correct, and
          up to date. You agree to use the Services only for purposes that are
          permitted by (a) the Terms and (b) any applicable law, regulation, or
          generally accepted practices or guidelines in the relevant
          jurisdictions (including any laws regarding the export of data or
          software to and from the United States, United Kingdom, India, and
          other relevant countries). You agree not to access (or attempt to
          access) any of the Services by any means other than through the
          interface that is provided by Kubric, unless you have been
          specifically allowed to do so in a separate agreement with Kubric. You
          specifically agree not to access (or attempt to access) any of the
          Services through any automated means (including use of scripts or web
          crawlers) and shall ensure that you comply with the instructions set
          out in any robots.txt file present on the Services. You agree that you
          will not engage in any activity that interferes with or disrupts the
          Services (or the servers and networks which are connected to the
          Services). Unless you have been specifically permitted to do so in a
          separate agreement with Kubric, you agree that you will not reproduce,
          duplicate, copy, sell, trade, or resell the Services for any purpose.
          You agree that you are solely responsible for (and that Kubric has no
          responsibility to you or to any third party for) any breach of your
          obligations under the Terms and for the consequences (including any
          loss or damage which Kubric may suffer) of any such breach.
        </p>
        <h2 class="heading">
          <a name="accounts,passwords,andsecurity">
            Accounts, passwords, and security
          </a>
        </h2>
        <p class="text">
          You agree and understand that you are responsible for not automating
          account creation. You are also responsible for maintaining the
          confidentiality of passwords associated with any account you use to
          access the Services. Accordingly, you agree that you will be solely
          responsible to Kubric for all activities that occur under your
          account. Kubric is not responsible whatsoever for any actions taken
          under your account. If you become aware of any unauthorized use of
          your password or of your account, you agree to notify Kubric
          immediately at welisten@Kubric.io.
        </p>
        <h2 class="heading">
          <a name="contentintheservices">Content in the services</a>
        </h2>
        <p class="text">
          You understand that all information (such as files, media, images,
          text) which you may have access to as part of, or through your use of,
          the Services is the sole responsibility of the source from which such
          content originated. All such information is referred to here as the
          "Content". Kubric does its best to give proper credit to the source
          from which the data was indexed. Kubric’s search engine lays no claim
          to the ownership of the content originated at these sites. If some
          content is cached, it is done for the sole purpose of providing users
          with the best possible service. If you think that any content is
          objectionable, please inform Kubric at your convenience. Kubric
          respects the intellectual property of others, and we ask our users to
          do the same. If you believe that your work is used in a way that
          constitutes copyright infringement, or your intellectual property
          rights have been otherwise violated, please provide Kubric the
          following information: A signature (electronic or physical) of the
          person authorized to act on behalf of the owner of the copyright or
          other intellectual property interest. A description of the copyrighted
          work or other intellectual property that you claim has been infringed.
          A description of where the material that you claim is infringing is
          located on the site. Your address, telephone number, and email
          address. A statement by you that you have good faith belief that the
          disputed use is not authorized by the copyright owner, its agent, or
          the law. A statement by you, made under penalty of perjury, that all
          the above information is accurate, and that you are the copyright or
          intellectual property owner or authorized to act on the behalf of such
          owner. You agree that you are solely responsible for (and that Kubric
          has no responsibility to you or to any third party for) any Content
          that you create, transmit, or display while using the Services and for
          the consequences of your actions (including any loss or damage which
          Kubric may suffer) by doing so. You understand that by using the
          Services you may be exposed to Content that you may find offensive,
          indecent, or objectionable and that, in this respect, you use the
          Services at your own risk.
        </p>
        <h2 class="heading">
          <a name="kubric’sandyourproprietaryrights">
            Kubric’s and your proprietary rights
          </a>
        </h2>
        <p class="text">
          Unless you have agreed otherwise in writing with Kubric, nothing in
          the Terms gives you a right to use any of Kubric’s trade names,
          trademarks, service marks, logos, domain names, and other distinctive
          brand features. You agree that you shall not remove, obscure, or alter
          any proprietary rights notices (including copyright and trade mark
          notices), which may be affixed to or contained within the Services.
          Unless you have been expressly authorized to do so in writing by
          Kubric, you agree that in using the Services, you will not use any
          trade mark, service mark, trade name, or logo of any company or
          organization in a way that is likely or intended to cause confusion
          about the owner or authorized user of such marks, names or logos.
        </p>
        <h2 class="heading">
          <a name="terminationofrelationship">Termination of relationship</a>
        </h2>
        <p class="text">
          Kubric may, at any time, terminate its legal agreement with you,
          including termination of your account, if you breach any provision of
          the Terms, as per the sole discretion of Kubric. Kubric holds the
          right to deny you Services without any express reason. Kubric may also
          terminate the relationship with you if Kubric is legally required to
          do so (e.g., when provision of Services provided by Kubric is, or
          becomes, unlawful); or if the partner(s) with whom Kubric offered the
          Services to you has terminated its relationship with Kubric or ceased
          to offer the Services to you; or the provision of the Services to you
          by Kubric is, in Kubric’s opinion, no longer commercially viable.
        </p>
        <h2 class="heading">
          <a name="exclusionofwarranties">Exclusion of warranties</a>
        </h2>
        <p class="text">
          You expressly understand and agree that your use of the services is at
          your sole risk and that the services are provided "as is" and "as
          available". In particular, Kubric, its subsidiaries and affiliates,
          and its licensors do not represent or warrant to you that:
        </p>
        <ul>
          <li class="text">
            Your use of the services will meet your requirements.
          </li>
          <li class="text">
            Your use of the services will be uninterrupted, timely, secure, or
            free from error.
          </li>
          <li class="text">
            Any information obtained by you as a result of your use of the
            services will be accurate or reliable.
          </li>
          <li class="text">
            That defects in the operation or functionality of any software
            provided to you as part of the services will be corrected.
          </li>
        </ul>
        <p class="text">
          Any material downloaded or otherwise obtained through the use of the
          services is done at your own discretion and risk, and you will be
          solely responsible for any damage to your computer system or other
          device, or loss of data that results from the download of any such
          material. No advice or information, whether oral or written, obtained
          by you from Kubric, or through or from the services shall create any
          warranty not expressly stated in the terms. Kubric further expressly
          disclaims all warranties and conditions of any kind, whether express
          or implied, including, but not limited to, the implied warranties and
          conditions of merchantability, fitness for a particular purpose, and
          non-infringement.
        </p>
        <h2 class="heading">
          <a name="limitationofliability">Limitation of liability</a>
        </h2>
        <p class="text">
          Kubric is never liable for any direct, indirect, incidental, special,
          consequential, or exemplary damages which may be incurred by you,
          however caused and under any theory of liability. This shall include,
          but not be limited to:
        </p>
        <ul>
          <li class="text">
            Any loss of profit (whether incurred directly or indirectly), any
            loss of goodwill or business reputation, any loss of data suffered,
            cost of procurement of substitute goods or services, or other
            intangible loss.
          </li>
          <li class="text">
            Any loss or damage which may be incurred by you, including but not
            limited to loss or damage as a result of
          </li>
          <li class="text">
            Any reliance placed by you on the completeness, accuracy or
            existence of any advertising, or as a result of any relationship or
            transaction between you and any advertiser or sponsor whose
            advertising appears on the services
          </li>
          <li class="text">
            Any changes which Kubric may make to the services, or for any
            permanent or temporary cessation in the provision of the services
            (or any features within the services)
          </li>
          <li class="text">
            The deletion of, corruption of, or failure to store any content and
            other communications data maintained or transmitted by or through
            your use of the services.
          </li>
          <li class="text">
            Your failure to provide Kubric with accurate account information.
          </li>
          <li class="text">
            Your failure to keep your password or account details secure and
            confidential.
          </li>
        </ul>
        <p class="text">
          The limitations on Kubric’s liability to you above shall apply whether
          or not Kubric has been advised of or should have been aware of the
          possibility of any such losses arising.
        </p>
        <h2 class="heading">
          <a name="advertisements">Advertisements</a>
        </h2>
        <p class="text">
          Some of the Services may be supported by advertising revenue and may
          display advertisements and promotions. These advertisements may be
          targeted to the content of information stored on the Services, queries
          made through the Services, or other information. The manner, mode, and
          extent of advertising by Kubric on the Services are subject to change
          without specific notice to you. In consideration for Kubric granting
          you access to and use of the Services, you agree that Kubric may place
          such advertising on the Services.
        </p>
        <h2 class="heading">
          <a name="othercontent">Other content</a>
        </h2>
        <p class="text">
          The Services may include hyperlinks to other web sites or content or
          resources. Kubric may have no control over any web sites or resources
          which are provided by companies or persons other than Kubric. You
          acknowledge and agree that Kubric is not responsible for the
          availability of any such external sites or resources, and does not
          endorse any advertising, products, or other materials on or available
          from such web sites or resources. You acknowledge and agree that
          Kubric is not liable for any loss or damage which may be incurred by
          you as a result of the availability of those external sites or
          resources, or as a result of any reliance placed by you on the
          completeness, accuracy or existence of any advertising, products or
          other materials on, or available from, such web sites or resources.
        </p>
        <h2 class="heading">
          <a name="generallegalterms">General legal terms</a>
        </h2>
        <p class="text">
          Sometimes when you use the Services, you may (as a result of, or
          through your use of the Services) use a service or download a piece of
          software, or media, or purchase goods, which are provided by another
          person or company. Your use of these other services, software, or
          goods may be subject to separate terms between you and the company or
          person concerned. In the event the Terms do not affect your legal
          relationship with these other companies or individuals. The Terms
          constitute the whole legal agreement between you and Kubric and govern
          your use of the Services (but excluding any services which Kubric may
          provide to you under a separate written agreement), and completely
          replace any prior agreements between you and Kubric in relation to the
          Services. You agree that Kubric may provide you with notices,
          including those regarding changes to the Terms, by email, regular
          mail, or postings on the Services. You agree that if Kubric does not
          exercise or enforce any legal right or remedy which is contained in
          the Terms (or which Kubric has the benefit of under any applicable
          law), this will not be taken to be a formal waiver of Kubric’s rights
          and that those rights or remedies will still be available to Kubric.
          If any court of law, having the jurisdiction to decide on this matter,
          rules that any provision of these Terms is invalid, then that
          provision will be removed from the Terms without affecting the rest of
          the Terms. The remaining provisions of the Terms will continue to be
          valid and enforceable.
        </p>
        <h2 class="heading">
          <a name="giftcards&amp;couponcodes">Gift cards &amp; coupon codes</a>
        </h2>
        <p class="text">
          Subject to the rest of these Terms and Conditions, by purchasing or
          using any physical or digital gift card for Kubric (“Gift Card”), you
          agree to the following:
        </p>
        <ul>
          <li class="text">
            Each Gift Card is valid only for a period of 365 days from the date
            of purchase.
          </li>
          <li class="text">
            Gift Cards can be redeemed on the Kubric website or app.
          </li>
          <li class="text">
            To redeem a Gift Card, you must register as a Kubric user and must
            be at least 18 years old.
          </li>
          <li class="text">Gift Cards may each be used only once.</li>
          <li class="text">
            Except as otherwise required by applicable law, Gift Cards cannot be
            redeemed for cash, returned, or re-sold.
          </li>
          <li class="text">
            Kubric is not responsible lost, stolen, or misused Gift Cards.
          </li>
        </ul>
      </div>
    </main>
  </Layout>
)
